import React, { Component, PureComponent, useContext } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
// import Carousel from 'nuka-carousel';
import About from './Pages/About'
import Order from './Order'
import Specials from './Pages/Specials'
import Catering from './Pages/Catering'

import './index.scss'
import './ThemeToggle/theme_night.scss'
// import './wine.scss'
import ThemeContext from '../../Context/ThemeContext'

import {sections} from './sections'
import ThemeToggle from './ThemeToggle'
import CartBar from './CartBar'
export {CartBar as StickyTopBar}



export const customOrderAppPaths = ["/menu", "/order"]

export const pages = [
  {path: "/order", label: "Order", component: Order },
  {path: "/menu", label: "Menu", component: Order },

  {path: "/specials", label: "Specials", component: Specials },
  {path: "/about", label: "About", component: About },
  // {path: "/nutrition", label: "Nutrition", component: Nutrition },
]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, external, hidden, unlisted}) => {
          if (hidden || unlisted) { return null}
          return (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        )})}
      </ul>
      <ThemeToggle></ThemeToggle>
    </nav>
  )}
}

const quickLinks = [
  // { to: '/order', label: 'order online'},
  { to: '/bakery', label: 'bakery'},
  { to: '/breakfast-lunch', label: 'marketplace'},
  { to: '/catering', label: 'catering'},
]

const foodImages = [
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-1.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-2.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-3.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-4.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-5.jpg"}
]



export function Header () {

    const {themeName} = useContext(ThemeContext)
    const heroUrl = (themeName === 'dark') ?
      "https://afag.imgix.net/romancing-the-bean-cafe/espress-dark.jpg?w=500"
      :
     "https://afag.imgix.net/romancing-the-bean-cafe/espresso-light.jpg?w=500"
    return (
      <div className="landing-page-wrapper">


        <div
          className="hero-banner">
          <div className="text">
            <h2 style={{
              // color: "black",
              // textShadow: "-1px 1px 0 green",
              // marginLeft: "1em",
              // fontSize: "2em"
            }}>
            <span className="welcome">welcome to</span>
            <br/>
            <img
              style={{
                maxWidth: "350px"
              }}
              src="https://afag.imgix.net/electric-lotus/logo.png?gam=100&invert=true" alt=""/>

            </h2>



            <p>
              <Link to="/order" className="order-online-button">Order Pickup / Delivery</Link>
            </p>
            {/* <p className="promo">
              <span className="promo-text">
                Get 15% Rewards only here.
              </span>
            </p> */}
          </div>
        </div>



        {/* <div className="carousel-hero">
          <Carousel
            className="carousel"
            autoplay={true}
            slideWidth={1}
            >
               <img src="https://afag.imgix.net/electric-lotus/hero.jpg?h=450&w=1000&fit=crop&auto=format"
                 alt="indian cuisine on dishes"
                />
               <img src="https://afag.imgix.net/electric-lotus/hero-1.jpg?h=450&w=1000&fit=crop&auto=format"
                 alt="indian spices"
                />
                <img src="https://afag.imgix.net/electric-lotus/hero.jpg?h=450&w=1000&fit=crop&auto=format"
                  alt="indian cuisine on dishes"
                 />
          </Carousel>
        </div> */}
        {/* <div className="info">

          <p>Open Everyday: 11am-10pm</p>
          <p>
            <Link to="/order" className="order-online-button">Order Pickup / Delivery</Link>
          </p>

          <p>1727 N. Vermont Ave, #102<br/>
            Los Angeles, CA 90027</p>

          <p><a href="tel:+13236693354">323.669.3354</a></p>
        </div> */}
        <div className="text-section">
          <div className="header">
            <div className="deco">
              {/* <img src="https://afag.imgix.net/sidewalk-grill/floral-icon.png?auto=format" alt=""/> */}
              <h1>
                <div className="title">Experience the Taste of India!</div>
                {/* <br/> */}
                {/* <span className="subtitle">Where great food meets great people</span> */}
              </h1>

            </div>
          </div>
          <div className="content">

            <p>We offer a richly-varied menu of fresh, natural ingredients, wholesome sauces and flavorful spices. All of our food is natural and freshly-prepared daily on the premises with no additives or preservatives.</p>
          </div>
        </div>



      </div>







    );

}


export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header">
        {/* <PoweredBy></PoweredBy> */}
      </div>
    );
  }
}

// export function subMenuFilter (subMenu) {
//   const tag = subMenu.tag
//   if (!tag) {return true}
//
//   const now = new Date()
//   const day = now.getDay() // Sunday - Saturday : 0 - 6
//   const hours = now.getHours() //0 to 23
//   const minutes = now.getMinutes() // 0 to 59
//   const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
//   const isBreakfast = (
//     (hours >= 0) && (hours < 11)
//   )
//   const isBrunch = (
//     (hours >= 0) && (hours < 15)
//   )
//   const isLunch = (
//     (hours >= 11) && (hours < 15)
//   )
//   const showLunchMenu = isWeekday && isLunch
//
//   const isDayBreakfast = tag.indexOf('weekday breakfast specials') !== -1 // m-f 6am - 11am
//   const isEndBreakfast = tag.indexOf('weekend breakfast specials') !== -1 // sat-sun 6am - 3pm
//   const isDayLunch =     tag.indexOf('weekday lunch specials') !== -1     // m-f 11am-3pm
//
//   if (isDayBreakfast && isWeekday && isBreakfast) { return true }
//   if (isEndBreakfast && !isWeekday && isBrunch) { return true }
//   if (isDayLunch && isWeekday && isLunch) {return true}
//   // if (isDinnerSubmenu && !showLunchMenu) { return true }
//
//   return false
// }
