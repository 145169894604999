import React from 'react'
import AutoMenu from '../../../react/AutoMenu'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="landing-page-wrapper">
      <div className="text-section">
        <div className="header">
          <div
            style={{
              background: `url(https://afag.imgix.net/electric-lotus/interior.jpg?w=1200)`,
              backgroundSize: `cover`,
              backgroundPosition: `center`,
              minHeight: `30vh`,
              display: `flex`,
              alignItems: `center`,
              justifyContent: `center`,
            }}
            className="image-header">
            <h1>About Us</h1>
          </div>
          <div className="deco">
            {/* <img src="https://afag.imgix.net/sidewalk-grill/floral-icon.png?auto=format" alt=""/> */}
            <h2>
              <div className="title">INDIAN CUISINE</div>
              <br/>
              <span className="subtitle">In business since 1999</span>
              <br/>

            </h2>

          </div>
        </div>
        <div className="content">
          <p>We at Electric Lotus are committed to preserving a culture, tradition and standard in culinary tastes that a Nawab would appreciate. It is more than sixteen years since the Electric Lotus restaurant group was established in Los Angeles, CA. Electric Lotus has since become an icon of Indian cuisine and established itself as the favored restaurant of curry connoisseurs across Southern California. If you are a first-time visitor, you will find our cuisine versatile, ranging from extremely mild to the richly pungent. We offer a richly-varied menu of fresh, natural ingredients, wholesome sauces and flavorful spices. All of our food is natural and freshly-prepared daily on the premises with no additives or preservatives.</p>
          {/* <p>Private selection wines. Draft beers. Full bar.</p> */}
          {/* <p>Enjoy the taste of our homemade sides and sauces, freshly prepared daily. Choose from a selection of salads, wraps or plates and enjoy the decadent aroma with your friends, family or co-workers. Come visit us today and have a taste.</p> */}
        </div>

        {/* <iframe width="445" height="315" src="https://www.youtube.com/embed/BmJQiCLH6Pk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
      </div>

    </div>
  )
}
