import React from 'react'
import AutoMenu from '../../../react/AutoMenu'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="landing-page-wrapper">
      <div className="text-section">
        <div className="header">
          <div
            style={{
              background: `url(https://afag.imgix.net/electric-lotus/grill.jpg?w=1200)`,
              backgroundSize: `cover`,
              backgroundPosition: `center`,
              minHeight: `30vh`,
              display: `flex`,
              alignItems: `center`,
              justifyContent: `center`,
            }}
            className="image-header">
            <h1>Our Specials</h1>
          </div>
          <div className="deco">
            {/* <img src="https://afag.imgix.net/sidewalk-grill/floral-icon.png?auto=format" alt=""/> */}
            <h2>
              <div className="title">Lunch Specials</div>
              <br/>
              <span className="subtitle">11:00 AM – 3:00 PM</span>
              <br/>

            </h2>

          </div>
        </div>
        <div className="content">
          <p>Vegetarian, Grill, Chicken, Lamb and Shrimp menu served with Rice and Naan.</p>
          <p>Private selection wines. Draft beers. Full bar.</p>
          {/* <p>Enjoy the taste of our homemade sides and sauces, freshly prepared daily. Choose from a selection of salads, wraps or plates and enjoy the decadent aroma with your friends, family or co-workers. Come visit us today and have a taste.</p> */}
        </div>

        {/* <iframe width="445" height="315" src="https://www.youtube.com/embed/BmJQiCLH6Pk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
      </div>

    </div>
  )
}
