import React from 'react'
import AutoMenu from '../../react/AutoMenu'
import {Link} from 'react-router-dom'
import './order.scss'
export default function () {
  return(
    <div className="ordering-page">
      {/* <div className="top-banner" style={{backgroundImage: "url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_1200/ure9ymqsporphmwvvitt)"}}>
        <h1>Catering</h1>
      </div> */}
      <div className="header">
        <h1><span className="text">Order Pickup / Delivery</span></h1>
      </div>
      {/* <p>Order</p> */}

      <div className="ordering">
        <AutoMenu />
      </div>


    </div>
  )
}
